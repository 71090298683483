@mixin font-bold {
    font-weight: 800;
}

@mixin font-normal {
    font-weight: 500;
}

@mixin p-typography {
    @include font-normal;
    color: $jpp-off-black;
    font-family: $proxima-nova;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
}

@mixin small-typography {
    @include font-normal;
    color: $jpp-off-black;
    font-family: $proxima-nova;
    font-size: 10px;
    line-height: 12px;
}

@mixin anchor-small-typography {
    font-size: 10px;
    line-height: 12px;
}

@mixin h1-typography {
    @include font-bold;
    color: $jpp-off-black;
    font-family: $proxima-nova;
    font-size: 26px;
    line-height: 100%;
    margin: 16px 0;
    text-transform: uppercase;

    @media (min-width: $bp-laptop) {
        font-size: 40px;
        margin: 24px 0;
    }
}

@mixin h2-typography {
    @include font-bold;
    color: $jpp-off-black;
    font-family: $proxima-nova;
    font-size: 24px;
    line-height: 120%;
    margin: 16px 0;
    text-transform: uppercase;

    @media (min-width: $bp-laptop) {
        font-size: 32px;
        margin: 24px 0;
    }
}


@mixin h3-typography {
    @include font-bold;
    color: $jpp-off-black;
    font-family: $proxima-nova;
    font-size: 22px;
    line-height: 120%;
    margin: 16px 0;
    text-transform: uppercase;

    @media (min-width: $bp-laptop) {
        font-size: 28px;
        margin: 24px 0;
    }
}

@mixin h4-typography {
    @include font-bold;
    color: $jpp-off-black;
    font-family: $proxima-nova;
    font-size: 18px;
    line-height: 120%;
    margin: 16px 0;
    text-transform: uppercase;

    @media (min-width: $bp-laptop) {
        font-size: 24px;
    }
}

@mixin h5-typography {
    @include font-bold;
    color: $jpp-off-black;
    font-family: $proxima-nova;
    font-size: 16px;
    line-height: 120%;
    margin: 16px 0;
    text-transform: uppercase;
}

@mixin link {
    @include smooth;
    color: $jpp-blue;
    text-decoration: underline; // sass-lint:disable-line no-important

    &:hover {
        color: $jpp-orange;
    }
}

@mixin unset-underline {
    text-decoration: none !important; // sass-lint:disable-line no-important
}

@mixin unset-hidden-props {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: auto;
    position: static;
    width: auto;
}

@mixin hide-props {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

@mixin clearfix {
    &::after {
        clear: both;
        content: '';
        display: table;
    }
}

@mixin clearfix-before {
    &::after {
        clear: both;
        content: '';
        display: table;
    }
}

@mixin icon($icon, $color, $size) {
    &::before {
        -moz-osx-font-smoothing: grayscale; // sass-lint:disable-line no-vendor-prefixes
        -webkit-font-smoothing: antialiased; // sass-lint:disable-line no-vendor-prefixes
        color: $color;
        content: $icon;
        display: inline-block;
        font-family: $icomoon;
        font-size: $size;
        vertical-align: sub;
    }
}

@mixin icon-after($icon, $color, $size) {
    &::after {
        -moz-osx-font-smoothing: grayscale; // sass-lint:disable-line no-vendor-prefixes
        -webkit-font-smoothing: antialiased; // sass-lint:disable-line no-vendor-prefixes
        color: $color;
        content: $icon;
        display: inline-block;
        font-family: $icomoon;
        font-size: $size;
        vertical-align: sub;
    }
}

@mixin full-width {
    left: 50%;
    margin-left: -50vw;
    max-width: 100vw;
    min-width: 100vw;
    position: relative;
}

@mixin grid-width($colum-count) {
    box-sizing: border-box;
    float: left;
    padding-left: $grid-padding;
    padding-right: $grid-padding;
    width: percentage($colum-count / $grid-column-count);
}

@mixin grid-width-margin($colum-count) {
    float: left;
    margin-left: $grid-padding / 2;
    margin-right: $grid-padding / 2;
    width: calc(#{percentage($colum-count / $grid-column-count)} - #{$grid-padding });
}

@mixin grid-collapse {
    margin-left: -$grid-padding;
    margin-right: -$grid-padding;
}

@mixin color-transition {
    @include smooth;
}

@mixin center-x-y {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

@mixin center-y {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

@mixin center-x {
    bottom: 0;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
}

@mixin line {
    position: relative;

    &:hover {
        &::before {
            @include smooth;
            opacity: 1;
            width: 80px;

            @media (max-width: 600px) {
                width: 44px;
            }
        }
    }

    &::before {
        background: $jpp-dark-grey;
        content: '';
        height: 1px;
        left: calc(100% + 8px);
        opacity: .33;
        pointer-events: none;
        position: absolute;
        top: 50%;
        user-select: none;
        width: 42px;

        @media (max-width: 600px) {
            width: 22px;
        }
    }
}

@mixin smooth {
    transition: all .24s ease, height 0s; // sass-lint:disable-line no-transition-all
    transition-delay: 0s;
}

@mixin ease {
    transition: all .3s ease; // sass-lint:disable-line no-transition-all
}

@mixin sidebar-box {
    background-color: $white;
    box-shadow: 0 2px 16px 0 $box-shadow-light;
    box-sizing: border-box;
    left: 0;
    margin-bottom: ($spacer / 2) + 2px;
    margin-top: 0;
    opacity: 1;
}


@mixin jpp-field-element {
    margin-bottom: 15px;
    position: relative;
    z-index: auto;

    &.input-filled,
    &.input-active {
        &,
        &.required {
            input,
            textarea,
            select {
                border-color: $jpp-success-green;
                color: $jpp-off-black;

                &:focus,
                &:active {
                    border-color: $jpp-blue;
                }
            }
        }

        input {
            color: $jpp-off-black;
        }

        label.label { // sass-lint:disable-line force-element-nesting no-qualifying-elements
            clip: rect(0, 0, 0, 0);
            color: transparent;
        }
    }

    &.input-active {
        &.input-no-value {
            label.label { // sass-lint:disable-line force-element-nesting no-qualifying-elements
                clip: unset;
                color: $light-grey;
                margin-left: 2px;
                opacity: .75;
            }
        }
    }

    &.configurable {
        label.label { // sass-lint:disable-line force-element-nesting no-qualifying-elements
            display: none;
        }
    }

    &.input-show-label {
        label.label { // sass-lint:disable-line force-element-nesting no-qualifying-elements
            clip: unset;
            color: $jpp-grey-2;
            font-size: 1.2rem;
            line-height: 1.2rem;
            transform: translate(1px, 3px);
        }
    }

    &._required,
    &.required {
        > label.label { // sass-lint:disable-line force-element-nesting no-qualifying-elements
            &::after {
                color: $jpp-blue;
                content: '*';
            }
        }
    }

    .field-error[generated], // sass-lint:disable-line force-attribute-nesting
    .mage-error[generated] { // sass-lint:disable-line force-attribute-nesting
        bottom: 0;
        font-size: 1.2rem;
        left: 0;
        margin: 0;
        padding:  0;
        position: relative;

        span {
            color: inherit;
            display: inline;
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
        }
    }

    &[name='billingAddresss2k_payment\.country_id'],
    &[name='billingAddresss2k_payment\.region_id'],
    &[name='shippingAddress\.country_id'],
    &[name='shippingAddress\.region_id'] {
        label.label { // sass-lint:disable-line force-element-nesting no-qualifying-elements
            span {
                color: transparent;
                font-size: 0;
            }

            &::after {
                margin-left: -2px;
                position: absolute;
                top: 0;
                z-index: auto;
            }
        }
    }
}

@mixin jpp-field-basic {
    background: $jpp-off-off-white;
    border-color: $jpp-off-off-white;
    border-radius: 0;
    border-style: solid;
    border-width: 1.5px;
    box-sizing: border-box;
    //color: $light-grey;
    color:#222;
    font-family: $proxima-nova;
    font-size: 1.4rem;
    font-weight: 500;
    height: 40px;
    line-height: 20px;
    padding: 10px;

    // sass-lint:disable no-vendor-prefixes force-pseudo-nesting
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active  {
        -webkit-box-shadow: 0 0 0 30px $jpp-off-off-white inset !important; // sass-lint:disable-line no-important
        -webkit-text-fill-color: $jpp-off-black;
    }
    // sass-lint:enable no-vendor-prefixes force-pseudo-nesting
}

@mixin jpp-input {
    &:not(.qty) {
        @include jpp-field-basic;
        @include ease;
        appearance: none;

        &:not(:placeholder-shown):not(.jpp-input) {
            border-color: $jpp-success-green;
        }

        &::placeholder {
            color: transparent;
        }
        &:not(.readonly):hover {
            background: #f5f5f5;
        }
        &:active,
        &:focus {
            border-color: $jpp-blue;
            box-shadow: none !important; // sass-lint:disable-line no-important
            outline: none !important; // sass-lint:disable-line no-important
        }

        &[aria-invalid='true'] {
            border-color: $jpp-error-red;
        }

        &[readonly='readonly'] {
            border-color: $jpp-grey-5;
            cursor: not-allowed;
            line-height: 30px;
            padding-bottom: 0;
        }
    }
}

@mixin jpp-textarea {
    @include jpp-field-basic;
    height: auto;
    &:not(.readonly):hover {
        background:#f5f5f5;
    }
    &::placeholder {
        color: transparent;
    }
}

@mixin jpp-select {
    @include jpp-field-basic;
    color:$light-grey;
    &:not(.readonly):hover {
        background:#f5f5f5;
    }
    option {
        color:#222;
    }
    option:first-child {
        color:$light-grey;
    }
    &:focus,
    &:active {
        border-color: $jpp-blue;
    }
}

@mixin jpp-label {
    clip: unset;
    color: $light-grey;
    float: none;
    font-size: 1.4rem;
    font-weight: 500;
    height: unset;
    left: 0;
    line-height: 4rem;
    margin: unset;
    padding: 0 0 0 10px;
    pointer-events: none;
    position: absolute;
    text-align: left;
    transition: .15s all ease; // sass-lint:disable-line no-transition-all
    white-space: nowrap;
    width: unset;
    z-index: 1;

    &::after {
        margin-left: 2px;
    }

    span {
        color: inherit;
        display: inline;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
    }

    &[for='s2k_payment_expiration'],
    &[for='country'],
    &[for='country_id'],
    &[for='region_id'] {
        span {
            color: transparent;
            font-size: 0;
        }

        &::after {
            margin-left: -2px;
            position: absolute;
            top: 0;
            z-index: auto;
        }
    }
}

@mixin account-buttons($type: 'secondary') {
    border-radius: 0;
    box-sizing: border-box;
    display: inline-block;
    font-size: 1.2rem;
    font-weight: 800;
    height: 3.2rem !important;
    line-height: 3.1rem !important;
    margin: 0;
    padding: 0 10px !important; // sass-lint:disable-line no-important
    text-decoration: none !important; // sass-lint:disable-line no-important
    text-transform: uppercase;
    @include ease;

    @if $type == 'primary' {
        background-color: $jpp-blue;
        border: 1px solid $jpp-blue;
        color: $white !important;
    }

    @else if $type == 'secondary' {
        background-color: transparent;
        border: 1px solid $jpp-dash-btn-border;
        color: $jpp-off-black !important;
    }

    @else if $type == 'orange' {
        background-color: $jpp-orange;
        border: 1px solid $jpp-orange;
        color: $white;
    }


    span {
        color: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        text-decoration: inherit;
    }

}

@mixin jpp-floating-label {
    label {
        font-size: 14px;
        position: absolute;
        text-align: left;
        transform: translateY(1px);
        transform-origin: top left;
        transition: all .1s ease-in-out; // sass-lint:disable-line no-transition-all
    }

    &.input-active {
        label {
            //move the x coordinate and reduce size
            clip: inherit;
            color: $black;
            transform: translateY(-30px) scale(1);
        }
    }

}

@mixin flex-container {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
}

@mixin flex-item {
    flex-basis: auto;
    flex-grow: 1;
}

@mixin inline-dl {
    margin: 0;
    overflow: auto;

    dt,
    dd {
        display: block;
        float: left;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.8rem;
        margin: 0;
    }

    dt {
        clear: left;
        margin-right: 5px;

        &::after {
            content: ': ';
        }
    }
}
