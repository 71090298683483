
body.catalogsearch-result-index {
    .page-title {
        font-size:24px;
        margin:8px 0 12px!important;
        span {
            font-size:24px;
        }
    }

    #algolia-left-container {
        @media (min-width:$bp-laptop) {
            transform:translateX(2px);
        }
    }
    #algolia_instant_selector {
        .text-ellipsis {
            overflow:visible;
            white-space:normal;
        }
        em {
            background-color:rgba(207,234,250,0.6);
        }
        svg {
            width:15px!important;
        }
        .instant-search-bar-label {
            background:$jpp-blue;
            font-size:14px;
        }
        #instant-search-bar {
            border:2px solid $jpp-blue;
        }
        .hits .infos {
            margin:0 15px 16px!important;
            background:#f2f6fb;
            font-size:14px;
            padding: 6px 10px;
            color: #aaa;
            text-align: center;
            clear: both;
            line-height: 32px;
            .sort-by-label {
                color:#000;
                transform:translateY(-1px);
            }
            strong, span {
                font-size:14px;
            }
            select {
                background:#fff;
                background-color:#fff;
                padding:0 7px!important;
                height:32px;
                margin-top:3px;
            }
            select, select option:first-of-type {
                color:#222;
            }
        }
    }
    #algolia_instant_selector .ais-header {
        margin-bottom:2px;
    }
    #algolia_instant_selector .facet {
        padding-bottom:2px;
    }
    .ais-current-refined-values--clear-all {
        color:#fff;
        text-decoration:none!important;
        margin:8px 6px 0 0;
        text-transform:capitalize;
        font-size:14px;
        &:hover {
            color:#fff!important;
        }
    }
    .ais-body.ais-refinement-list--body,
    .ais-Panel-body {
        max-height:216px;
        overflow: auto;

        span.ais-refinement-list--count,
        .ais-RefinementList-count,
        .ais-HierarchicalMenu-count {
            font-size:14px;
            float: right;
            color: #666;

            &:before,
            &:after {
                display: none;
            }
        }
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            margin-right: 2px;
            width: 7px;
        }

        &::-webkit-scrollbar-thumb {
            -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
            background-color: $jpp-blue;
            border-radius: 0;
            opacity: .5;
        }

        &::-webkit-scrollbar-track {
            background: rgba(2, 2, 2, .05);
        }

    }
    .ais-price-ranges--form,
    .ais-RangeInput-form {
        margin-bottom:6px;
        span {
            font-size:14px;
        }
        .ais-price-ranges--input,
        .ais-RangeInput-input {
            height:28.67px;
            border-color: #68bb5b;
            width: 70px;

            &:focus {
                border-color: #0088ce;
            }
        }
        .ais-price-ranges--button,
        .ais-RangeInput-submit {
            background: #39C none repeat scroll 0 0;
            padding: 5px 15px;
            margin-left: 10px;
            border: 0 none;
            font-size: 13px;
            font-weight: normal;
            line-height: 19px;
            text-align: center;
            text-transform: uppercase;
            vertical-align: middle;
            color:#fff;
            border-radius:0px;
            &:hover {
                background:#0088ce!important;
                border:0px solid transparent;
            }
        }
    }
    .ais-current-refined-values--item {
        .current-refinement-label, .current-refinement-name {
            font-size:14px;
        }
    }
    .clear-refinement {
        transform:scale(0.66) translateY(0.5px);
    }
    .ais-infinite-hits--showmore {
        .ais-infinite-hits--showmoreButton {
            font-family:"proxima-nova";
            font-weight:800;
            &:disabled {
                opacity:0;
            }
        }
    }

    .ais-hierarchical-menu--item__active > div > .ais-hierearchical-link-wrapper > .ais-hierarchical-menu--link,
    .ais-refinement-list--item__active > div > .ais-hierarchical-list--link,
    .ais-refinement-list--item__active > div > .ais-refinement-list--label,
    .ais-price-ranges--item__active > .ais-price-ranges--link {
        box-shadow:0px 0px 0px transparent;
    }
    .sbx-sffv-disabled {
        display:none;
    }
}

.page-header {
    .block-search {
        @media (min-width: $bp-tablet) {
            clear: both;
            display: block;
            float: none;
            padding: 0 0 $spacer;
            width: 100%;
        }

        @media (min-width: $bp-laptop) {
            @include grid-width(4);
            clear: none;
        }

        .block-content {
            align-items: center;
            display: flex;
        }

        .search-switch {
            color: $jpp-off-black;
            display: inline-block;
            height: 50px;
            width: 102px;
            padding-right:0;
        }

        .algolia-autocomplete {
            margin: 0;
        }
    }
}

#search_mini_form,
#search_mini_form_resources {
    display: inline-block;
    vertical-align: middle;
    width:100%;

    @media (min-width:$bp-laptop) {
        width: calc(100% - 114px);
    }
    label {
        display: none;
    }

    &.hide {
        display: none !important;
    }

    input {
        &#search_resources,
        &#search {
            color: #222;
            height: 50px;
            line-height: 30px;
            position: static !important;
            @supports (-webkit-touch-callout: none) {
                font-size: 1.6rem;
            }

            @media (min-width:$bp-laptop) {
                min-width:272px;
            }

            &:not(:placeholder-shown) {
                border-color: $jpp-off-off-white;
            }

            &::placeholder {
                color: $light-grey;
            }

            &[aria-invalid='true'] {
                border-color: $jpp-error-red;
            }

            &:active,
            &:focus {
                border-color: $jpp-blue;
            }
        }
    }
}

#algolia-searchbox {
    .algolia-autocomplete {

        input {
            position: unset !important;
        }
    }

    .magnifying-glass {
        display: none;
    }
}

#algolia-autocomplete-container,
#algolia-autocomplete-container.reverse {
    .autocomplete-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        max-width: 100%;

        &.algolia-resources-wrapper {
            #autocomplete-products-footer {
                left: auto;
                right: 0;
                width: 30%;
            }
        }
    }

    .col3,
    .col9 {
        min-width: 100%;
        @media (min-width: $bp-tablet) {
            min-width: 0;
            flex: 0 0 auto;
        }

        .category {
            border-bottom: 2px solid $tile-blue-2;
            color: $tile-blue-2;
            font-size: 16px;
            font-weight: 700;
        }
    }

    .col3 {
        @media (min-width: $bp-tablet) {
            background: $jpp-off-white;
            flex-basis: 25%;
        }

        .aa-dataset-products {
            margin: 20px 10px 70px;

            .aa-suggestion {
                width: 100%;
            }
        }
        .other-sections {
            .aa-suggestion {
                display: inline-block;
                width: 100%;
            }
        }
    }

    .col9 {
        display: block;
        flex: unset;
        @media (min-width: $bp-tablet) {
            flex-basis: 75%;
        }

        .aa-dataset-products {
            .category {
                display: none;
            }
        }

        .other-sections {
            .aa-suggestions {
                margin-top: 10px;
            }

            .aa-suggestion {
                border-bottom: 1px solid $box-shadow-light;
            }
        }
    }

    .aa-dropdown-menu {
        left: 0 !important;
        margin-top: 20px;
        width: 100%;

        .algoliasearch-autocomplete-hit {
            @include unset-underline;
        }

        .algoliasearch-autocomplete-sku {
            color: $jpp-blue;
            font-size: 12px;
        }
    }

    #autocomplete-products-footer {
        background: $jpp-off-white;
        box-sizing: border-box;
        font-size: 14px;
        padding: 15px;

        a {
            @include unset-underline;
            font-size: 14px;
            font-weight: 700;
        }
    }
}

#algolia_instant_selector {
    #instant-search-bar {
        width: calc(100% - 150px);
        padding-bottom: 4px;
    }
    .instant-search-bar-label {
        width: 150px;
    }
    .sort-by-label {
        padding-top: 5px;
        margin-right: 8px;
    }
}

#algolia_instant_selector {
    .ais-current-refined-values.facet,
    .ais-Panel {
        .ais-current-refined-values--body {
            padding-left: 8px;
            padding-right: 8px;
        }
    }
    .facet,
    .ais-Panel {
        .name {
            background: $jpp-blue;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            color: #fff;
            font-weight: 700;
            height: 40px;
            line-height: 32px;
            margin-bottom: 0;
            padding: 4px 8px;
            text-transform: uppercase;
        }
    }
    .ais-hierarchical-menu--item,
    .ais-HierarchicalMenu-item {
        font-size: inherit;
        span {
            font-size:14px;
            color:$black;
        }
        a {
            color: $jpp-off-black;
            display: block;
            text-decoration: none !important;
            font-size: inherit;
            overflow: hidden;

            &:hover {
                color: $jpp-blue;
            }
        }
    }
    .ais-body,
    .ais-Panel-body {
        padding-left: 10px;
        padding-right: 10px;

        .ais-refinement-list--item,
        .ais-RefinementList-item {
            text-decoration: none !important;
            font-size: inherit;
        }
        .ais-refinement-list--label,
        .ais-RefinementList-label  {
            color: $jpp-off-black;
            text-decoration: none !important;
            &:hover {
                color: $jpp-blue;
            }
        }
        .ais-price-ranges--link {
            color: $jpp-off-black;
            text-decoration: none !important;
            &:hover {
                color: $jpp-blue;
            }
        }
    }
}
#current-refinements {
    .ais-body {
        .ais-current-refined-values--item {
            .ais-current-refined-values--link {
                width: 100%;
            }
        }
        .current-refinement-wrapper{
            text-decoration: none;
            color: $jpp-grey-3;
        }
    }
}

#algolia-right-container {
    .infos {
        .ais-stats {
            padding-top: 5px;
        }
    }
}

#instant-search-results-container {
    padding:0 15px;
    margin-bottom:48px;
    @media (min-width:$bp-laptop) {
        margin-bottom:80px;
    }
    .ais-infinite-hits,
    .ais-InfiniteHits-list {
        display:flex;
        flex-wrap:wrap;
        .ais-infinite-hits--item,
        .ais-InfiniteHits-item {
            width:calc(50% - 8px);
            margin-right:16px;
            &:nth-of-type(2n) {
                margin-right:0;
            }
            @media (min-width:640px) {
                width:calc(33.333% - 10.666px);
                &:nth-of-type(2n) {
                    margin-right:16px;
                }
                &:nth-of-type(3n) {
                    margin-right:0;
                }
            }
        }
    }
    .result-wrapper {
        padding:0;
        a {
            text-decoration: none !important;
        }
        .result-title {
            text-align: left;
            font-weight: 800;
            color: $jpp-off-black;
            font-family: "proxima-nova",sans-serif;
            font-size: 16px;
            line-height: 120%;
            text-transform: uppercase;
            text-decoration: none !important;
            margin: 0;
        }
        .price-wrapper-main {
            text-align: left;
            position:absolute;
            bottom:16px;
            span {
                font-weight: 800;
                font-family: "proxima-nova",sans-serif;
                line-height: 120%;
                text-transform: uppercase;
                color: $jpp-blue;
            }
            .price-loader img{
                min-height:100px;
                top:50%;
                transform:translateX(-42%) translateY(40%);

            }
            .saved-price{
                font-size: 1.6em;

            }
        }
    }

    .product-item {
        @include smooth;
        border: 1px solid $jpp-light-grey;
        margin: 0 16px 16px 0;
        padding:16px!important;
        min-height: 500px;
        position: relative;
        width: 100%;
        display: block;
        background-color: #fff;

        &:hover {
            border-color: $jpp-blue;
        }
        .rating-summary .rating-result>span:before {
            color:$jpp-orange;
        }
        .result-sub-content {
            text-align: left;
        }
        @media (min-width: $bp-mobile) {
            min-height: 500px;
        }

        @media (max-width: 639px) {
            &:nth-child(2n) {
                margin-right: 0;
            }
        }

        @media (min-width: 640px) {
            &:nth-child(3n) {
                margin-right: 0;
            }
        }

        @media (min-width: $bp-laptop-xl) {
            min-height: 400px;

            &:nth-child(3n + 1) {
                margin-left: 0;
            }
        }

        img {
            height: auto !important;
            max-height: 150px;
        }


        .product-item-photo {
            display: block;
            text-align: center;
        }

        .product-item-name {
            a {
                @include h5-typography;
                @include unset-underline;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                display: -webkit-box;
                margin: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: normal;
            }
        }

        .sku-container {
            color: #555;
            font-size: 12px;
            line-height: 16px;
            margin: 4px 0;
            max-height: 34px;
            overflow-y: auto;

            &::-webkit-scrollbar {
                -webkit-appearance: none;
                margin-right: 2px;
                width: 7px;
            }

            &::-webkit-scrollbar-thumb {
                -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
                background-color: $jpp-blue;
                border-radius: 0;
                opacity: .5;
            }

            &::-webkit-scrollbar-track {
                background: rgba(2, 2, 2, .05);
            }

            > span {
                font-size: 12px;
                line-height: 16px;
            }

            .sku {
                color: $jpp-blue;
            }
        }

        .attribute-icons {
            float: none;
            margin-top: 6px;
            margin-bottom:0;
            .attribute-icon {
                height: 26px;
                width: 26px;
                margin-bottom:6px;
            }
        }

        .product-reviews-summary {
            margin: 0;

            .rating-summary,
            .reviews-actions {
                @media (max-width:991px) {
                    display: block;
                }
            }

            .rating-summary {
                left: unset;
            }

            .reviews-actions {
                a {
                    @include unset-underline;
                    color: $jpp-off-black;
                    font-size: 12px;
                    line-height: 28px;
                    margin-top: -6px;

                    span {
                        font-size: 12px;
                    }
                }
            }
        }

        .price-box {
            bottom: 0;
            left: 12px;
            margin: 0px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            @media (min-width: $bp-tablet) {
                left: 16px;
            }
            @media (min-width: $bp-desktop) {
                left: 24px;
            }
            .price-label,
            .price-label::after {
                display: none;
            }

            .special-price {
                order: 2;
                .price-container {
                    line-height: 2.8rem;
                }
            }

            .old-price {
                flex-basis: 100%;
                order: 1;
                text-decoration:none!important;
                .price-wrapper {
                    position:relative;
                    span {
                        font-size:14px;
                        color:#a2a2a2;
                    }
                    &::before {
                        position:absolute;
                        content:'';
                        left:0;
                        right:0;
                        top:50%;
                        transform:translateY(-20%);
                        height:1px;
                        background:#636363;
                    }
                }
                &,
                span,
                .price {
                    font-weight: 500;
                    color: $jpp-grey-2;
                }

                .price {
                    font-size: 1.6rem;
                    line-height: 1.6rem;
                }
            }

            .savings {
                order: 3;
                background:linear-gradient(120deg, #68BB5B 0%, #5BBB8B 100%);
                border-radius:4px;
                color: white;
                font-size: 1.4rem;
                font-weight: 600;
                line-height: 2.6rem;
                padding-left: 10px;
                padding-right: 10px;
                margin-left: 10px;
                transform:translateY(-2px);
                text-transform:none;
                em {
                    background:linear-gradient(120deg, #68BB5B 0%, #5BBB8B 100%) !important;
                    font-style: normal;
                }
            }

            .price {
                font-size: 1.4em;
                color: $jpp-blue;
            }
        }
    }

    .ais-infinite-hits--showmoreButton,
    .ais-InfiniteHits--showmoreButton {
        &:not(disabled) {
            background:$jpp-blue;
            color:#fff;
            height:40px;
            border-radius:0px;
            @include ease;
            font-size:14px;
            &:hover {
                background:$jpp-blue-hover;
            }
        }
    }
}
.ais-InfiniteHits {
    padding-top: 0;
}
.ais-Panel-header {
    padding: 0;
}
.ais-RangeInput-label {
    position: relative;
    padding-left: 10px;

    &:before {
        content: '$';
        position: absolute;
        left: 0;
        top: 5px;
    }
}
.ais-RangeInput-separator {
    margin: 0 5px;
}
.ais-ClearRefinements-button:after {
    content: ' Filters'
}



.search-area {
    flex:1;
    position:relative;
    z-index:1;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-self:flex-center;
    order:2;
    @media (min-width:992px) {
        margin-left:80px;
        margin-right:80px;
    }
    @media (min-width:1368px) {
        margin-left:100px;
        margin-right:100px;
    }
    .search-wrapper {
        height:40px;
        position:relative;
        //margin:16px 0;
        z-index:100;
        @media (max-width:991px) {
            margin-bottom:10px;
        }
        &.active {
            .background {
                opacity:1;
                pointer-events:auto;
                transition:all 0s, opacity 0.64s ease;
            }
        }
        .background {
            position:fixed;
            content:'';
            z-index:-1;
            top:0;
            left:50%;
            transform:translateX(-50%);
            width:100vw;
            height:100vh;
            background:rgba(0,0,0,0.2);
            opacity:0;
            pointer-events:none;
            transition:all 0.24s ease, width 0s, height 0s;
        }
    }


    input#jpp-search {
        height:40px;
        z-index:2;
        width:100%;
        background:#F3F3F3;
        font-weight:600;
        padding:10px 12px;
        transition:0.44s ease;
        border-radius:10px;
        border:1px solid #0088ce;
        outline:none;
        &:focus {
            outline:none;
        }
        &::placeholder {
            opacity:0.68;
        }
        &.filled {
            box-shadow:inset 0 0 10px #e7e7e7;
            border-radius:50px 100px 100px 50px;
            transition:1.4s ease;
        }
        &.active {
            border-color:$jpp-blue;
            &::placeholder {
                opacity:1;
            }
        }
    }
    div.submit {
        position:absolute;
        top:50%;
        transform:translateY(-50%);
        right:4px;
        z-index:3;
        @include smooth;
        transform-origin:top;
        svg {
            position:absolute;
            top:50%;
            left:50%;
            transform:translateX(-50%) translateY(-50%) scale(0.8);
            pointer-events:none;
            z-index:1;
            path {
                transition:0.44s ease;
            }
        }
        .submit-button {
            width:30px;
            height:30px;
            background:transparent;
            border-radius:100%;
            color:transparent;
            border:0;
            transition:0.44s ease;
            cursor:pointer;
        }
        &.filled:hover, &.active {
            transform:scale(1.28) translateY(-50%);
            .submit-button {
                background:$jpp-blue;
            }
            svg {
                path {
                    stroke:#fff;
                }
            }
        }
        &:hover {
            .submit-button {
                filter:brightness(1.1);
            }
        }
    }
}

.search-results-panel {
    position:absolute;
    top:46px;
    left:-11px;
    transform:perspective(250px) translateZ(-5px);
    width:895px;
    max-width:calc(92vw + 10px);
    pointer-events:none;
    background:#fff;
    opacity:0;
    transition:all 0.24s ease, height 0.6s ease, min-height 0.6s ease, width 0s;
    z-index:5000;
    display:flex;
    max-height:calc(100vh - 132px);
    overflow:hidden;
    @media (max-width:768px) {
        max-width:calc(100vw - 32px);
        left:50%;
        height:auto;
    }
    @media (max-width:480px) {
        left:1px;
    }
    &.active {
        opacity:1;
        pointer-events:auto;
        transform:perspective(250px) translateZ(0);
        box-shadow:0px 0px 24px rgba(2,2,2,0.12);
    }
    &.history {
        @media (min-width:992px) {
            height:278px;
        }
       /* .suggestions-column {
            > div {
                height:100%;
            }
        }*/

        &.queriesOnly {
            width:320px;
            transition:all 0.24s ease, height 0.6s ease, min-height 0.6s ease, width 0s;
            .panel-product-area {
                display:none;
            }
        }
        &.noHistory {
            display:none;
        }
    }
    &.results {
        @media (min-width:992px) {
            height:464px;
        }
    }
    > div {
        position:absolute;
        top:0;
        left:0;
        right:0;
        opacity:0;
        pointer-events:none;
        z-index:1;
        display:flex;
        width:100%;
        height:100%;
        &.active {
            opacity:1;
            pointer-events:auto;
            position:relative;
            z-index:2;
        }
    }
    .suggestions-column {
        width:235px;
        background:#FFF;
        display:flex;
        flex-direction:column;
        padding:24px;
        position:relative;
        overflow-y:scroll;
        @media (max-width:480px) {
            padding:16px;
        }
        &::-webkit-scrollbar {
            width:0px;
        }

        @media (max-width:991px) {
            //width:100%;
        }
        > div {
            display:flex;
            flex-direction:column;
            @media (max-width:991px) {
                //width:100%;
            }
            &:nth-of-type(1) {
                label {
                    margin-top:0;
                    margin-bottom:8px;
                }
            }
            &:nth-of-type(3) {
                .suggestion:nth-of-type(3) {
                    margin-bottom:0;
                }
            }
            &:nth-of-type(2), &:nth-of-type(3) {
                @media (max-width:991px) {
                    //display:none;
                }
            }

            label {
                margin-top:12px;
                margin-bottom:8px;
            }

            .suggestions-wrapper {
                display:flex;
                flex-direction:column;

                @media (max-width:991px) {
                    overflow-x:scroll;
                }
            }
        }

        .query-suggestion {
            display:flex;
            color:#000;
            align-self:flex-start;
            cursor:pointer;
            line-height:1.2;
            margin-bottom:0;
            padding-right: 8px;
            max-width: 50%;
            svg {
                transform:translateY(1px);
                margin-right:8px;
                display:none;
                path {
                    transition:0.18s ease;
                }
            }
            span {
                font-size:15px;
                font-weight:500;
                margin-bottom:0;
                transition:0.18s ease;
            }
            &:hover {
                span {
                    color:$jpp-blue;
                }
                svg path {
                    stroke:$jpp-blue;
                }
            }
        }
        .suggestion {
            border-radius:64px;
            color:#000;
            align-self:flex-start;
            margin-bottom:7px;
            cursor:pointer;
            span {
                line-height:1.2;
                user-select:none;
                display:block;
                text-align:left;
                font-size:15px;
                font-weight:500;
                transition:0.18s ease;
            }
            &:hover {
                span {
                    color:$jpp-blue;
                }
            }
        }
    }
    label {
        font-size:16px;
        font-weight:700;
    }
    .panel-product-area {
        flex:1;
        position:relative;
        display:flex;
        flex-direction:column;
        background:#F7F7F7;
        @media (max-width:768px) {
            order:1;
            min-height:253px;
            max-height:253px;
            margin-bottom:0;
            margin-top:auto;
            background:transparent;
        }
        &.hidden {
            display:none;
        }
    }
    .search-history {
        @media (max-width:768px) {
            flex-direction:column;
        }
    }
    .search-results {
        @media (max-width:768px) {
            flex-direction:column;
        }
        .submit-search {
            height:60px;
            background:$jpp-blue;
            border-radius:100px;
            width:588px;
            max-width:calc(100% - 32px);
            position:absolute;
            z-index:5;
            left:50%;
            transform:translateX(-50%);
            bottom:24px;
            display:flex;
            justify-content:center;
            transition:0.6s ease;
            @media (max-width:1368px) {
                height:48px;
                width:360px;
            }
            span {
                display:inline-block;
                color:#fff;
                text-align:center;
                pointer-events:none;
                line-height:60px;
                user-select:none;
                font-size:16px;
                font-weight:700;
                @media (max-width:1368px) {
                    line-height:48px;
                    font-size:14px;
                }
            }

            svg {
                margin-left:12px;
                top:50%;
                transform:translateY(-50%) scale(1.1);
                position:relative;
                @media (max-width:1368px) {
                    transform:translateY(-50%) scale(1);
                }
                path {
                    stroke:#fff;
                }
            }
            &:hover {
                filter:brightness(1.1);
                box-shadow:0px 0px 12px rgba(2,2,2,0.12);
            }
        }
    }
    .results-header {
        width:100%;
        padding:24px 24px 0;
        display:flex;
        position:relative;
        z-index:5;
        @media (max-width:480px) {
            padding:16px 16px 0;
        }
        .results-options {
            display:flex;
            transform:translateY(-6px);
            margin-left:24px;
            position:relative;
            .filter {
                position:relative;
                margin-right:12px;
                &:last-of-type {
                    margin-right:0;
                }
                &.active {
                    > a {
                        background:#fff;
                        border-color:$jpp-blue;
                        svg {
                            path {
                                stroke:$jpp-blue;
                            }
                        }
                    }
                }
                a {
                    height:36px;
                    line-height:34px;
                    user-select:none;
                    padding:0 12px;
                    background:#f5f5f5;
                    border:1px solid #DCDCDC;
                    border-radius:4px;
                    position:relative;
                    color:#000;
                    font-weight:700;
                    display:inline-block;
                    min-width:124px;
                    cursor:pointer;
                    &.filtering {
                        background:$jpp-blue;
                        span {
                            color:#fff;
                            svg {
                                path {
                                    stroke:#fff;
                                }
                            }
                        }
                    }
                    span {
                        font-size:14px;
                        pointer-events:none;
                    }
                    svg {
                        position:absolute;
                        top:50%;
                        transform:translateY(-50%);
                        right:8px;
                    }
                }
                .dropdown {
                    position:absolute;
                    display:flex;
                    flex-direction:column;
                    opacity:0;
                    background:#F5F5F5;
                    border:1px solid #c3c3c3;
                    padding:12px;
                    border-radius:4px;
                    transform:translateY(16px);
                    pointer-events:none;
                    @include smooth;
                    box-shadow:0px 10px 16px -2px rgba(2,2,2,0.36);
                    .option {
                        display:flex;
                        margin-bottom:8px;
                        background:#fff;
                        border-radius:4px;
                        min-width:160px;
                        transition:0.18s ease;
                        &:last-of-type {
                            margin-bottom:0;
                        }
                        span.option-title {
                            font-size:14px;
                            transition:0.18s ease;
                        }
                        span.option-qty {
                            align-self:flex-end;
                            margin-right:0;
                            margin-left:auto;
                            font-size:11px;
                            position:absolute;
                            display:inline-block;
                            background:#E6E6E6;
                            color:#6A6A6A;
                            height:16px;
                            line-height:16px;
                            top:50%;
                            right:8px;
                            transform:translateY(-50%);
                            border-radius:4px;
                            padding:1px 4px 0;
                            transition:0.18s ease;
                        }
                        &:hover, &.active {
                            border-color:$jpp-blue;
                            span.option-title {
                                color:$jpp-blue;
                            }
                            span.option-qty {
                                color:$jpp-blue;
                                background:rgba(0,136,206,0.15);
                            }
                        }
                    }
                }
                &.active {
                    > a {
                        color:$jpp-blue;
                        border-color:$jpp-blue;
                        background:#fff;
                    }
                    .dropdown {
                        pointer-events:auto;
                        opacity:1;
                        transform:translateY(8px);
                    }
                }
            }
        }
    }

    .close, .clear {
        position:absolute;
        right:24px;
        top:24px;
        color:#555;
        opacity:0.8;
        font-size:12px;
        user-select:none;
        z-index:20;
        cursor:pointer;
        text-decoration:none!important;
        &:hover {
            opacity:1;
            color:$jpp-blue;
        }
    }
    .close {
        @media (max-width:991px) {
            top:12px;
            right:16px;
        }
    }
    .clear {
        color:$jpp-blue;
        right:4px;
        top:4px;
        @media (max-width:991px) {
            right:auto;
            left:156px;
        }
    }
    .view-modes {
        display:flex;
        position:absolute;
        bottom:24px;
        right:24px;
        z-index:10;
        display:none;
        span {
            font-size:10px;
            color:#555;
            opacity:0.8;
            display:block;
            margin-right:10px;
            transform:translateY(9px);
            pointer-events:none;
            user-select:none;
        }
        .toggle {
            border:1px solid #dfdfdf;
            padding:4px;
            cursor:pointer;
            display:block;
            transition:0.18s ease;
            position:relative;
            &:not(.active) {
                opacity:0.7;
            }
            &:hover {
                opacity:1;
                &:before {
                    opacity:1;
                    transform:translateX(-50%) translateY(0);
                }
            }
            &:before {
                position:absolute;
                content:'';
                white-space:nowrap;
                padding:4px 8px;
                background:$jpp-blue;
                color:#fff;
                font-size:12px;
                text-align:center;
                left:50%;
                transform:translateX(-50%) translateY(6px);
                bottom:calc(100% + 8px);
                opacity:0;
                pointer-events:none;
                transition:0.18s ease;
                border-radius:4px;
                box-shadow:0px 2px 8px rgba(2,2,2,0.32);
            }
            &.active {
                border-color:$jpp-blue;
                cursor:default;
                svg {
                    g > g {
                        stroke:$jpp-blue;
                        fill:$jpp-blue;
                    }
                }
            }
            svg {
                pointer-events:none;
                g > g {
                    stroke:#707070;
                    fill:transparent;
                    transition:0.18s ease;
                }
            }
            &.simple {
                &:before {
                    content:'Simple';
                }

            }
            &.enhanced {
                &:before {
                    content:'Enhanced';
                }

            }
        }
    }
    .product-results {
        display:flex;
        flex-wrap:wrap;
        align-content:flex-start;
        flex:1;
        padding:20px 24px 24px 24px;
        @include smooth;
        position:relative;
        z-index:2;
        overflow-y:scroll; //todo, only engage when results outerheight exceeds necessary interior
        overflow-y:overlay;
        @media (max-width:1368px) {
            padding-top:12px;
        }
        @media (max-width:768px) {
            flex-direction:column;
            width:100%;
            overflow-x:scroll;
            overflow-y:hidden;
        }
        @media (max-width:480px) {
            padding:12px 16px 16px 16px;

        }
        &:not(.enhanced):not(.history) {
            @media (min-width:769px) {
                overflow:hidden;
            }
        }
        &.changing {
            opacity:0;
            pointer-events:none;
        }

        &::-webkit-scrollbar {
            width: 8px;
            height:8px;
        }
        &::-webkit-scrollbar-track {
            background: #f9f9f9;
        }
        &::-webkit-scrollbar-thumb {
            background: #dbdbdb;
            border-radius:8px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background:$jpp-blue;
        }

        .product {
            position:relative;
            margin-right:12px;
            margin-bottom:12px;
            width:calc(50% - 6px);
            //transition:all 0.2s ease, height 0s, width 0s;
            transition:all 0s, border 0.2s ease, box-shadow 0.2s ease, background 0.2s ease;
            background:#fff;
            align-self:flex-start;
            border:1px solid #E9E9E9;
            height:84px;

            @media (max-width:1368px) {
                width:calc(50% - 5px);
                margin-right:10px;
                margin-bottom:10px;
            }
            @media (max-width:991px) {
                width:calc(100% - 20px);
                filter:brightness(0.98);
                &:nth-of-type(2n) {
                    margin-bottom:0;
                }
                &:nth-last-of-type(2), &:last-of-type {
                    margin-right:0;
                }
            }
            &:nth-of-type(2n) {
                margin-right:0;
            }
            &:hover {
                border-color:rgba(0,136,206,0.4);
                box-shadow:0px 0px 12px rgba(2,2,2,0.12);
            }
            * {
                transition:all 0.2s ease, height 0s, width 0s;
            }

            &.changing {
                transition:all 0s, opacity 0.2s!important;
                * {
                    transition:all 0s, opacity 0.2s!important;
                }
            }


            a {
                height:100%;
                width:100%;
                position:relative;
                display:block;
                color:#000;
                > div {
                    flex-direction:row;
                    position:absolute;
                    top:0;
                    left:0;
                    right:0;
                    bottom:0;
                    padding:16px;
                    display:flex;
                }
                .image {
                    position:relative;
                    display:flex;
                    justify-content:center;
                    height:100%;
                    aspect-ratio:1/1;
                    margin-right:14px;
                    transform:scale(1.05);
                    img {
                        width:100%;
                        font-size:8px;
                    }
                }
                .info {
                    display:flex;
                    flex-direction:column;
                }
                .product-name {
                    font-size:13.5px;
                    font-weight:600;
                    color:#000;
                    margin-bottom:4px;
                }
                .sku {
                    font-size:12px;
                    color:#666;
                    text-transform:uppercase;
                    display:none;
                }
            }

        }


        &.enhanced {
            height:calc(100% - 60px);
            padding-top:12px;
            .product {
                border:1px solid #e3e3e3;
                width:calc(20% - 12.8px);
                &:nth-of-type(4n) {
                    margin-right:16px;
                }
                &:nth-of-type(5n) {
                    margin-right:0;
                }
                @media (max-width:1368px) {
                    width:calc(25% - 9px);
                    margin-right:12px;
                    margin-bottom:12px;
                    &:nth-of-type(5n) {
                        margin-right:12px;
                    }
                    &:nth-of-type(4n) {
                        margin-right:0;
                    }
                    &:nth-of-type(3n) {
                        margin-right:12px;
                    }
                }
                a {
                    padding-bottom:100%;
                    .image {
                        position:relative;
                        display:flex;
                        justify-content:center;
                        height:152px;
                        padding:0;
                        margin-top:0;
                        margin-bottom:auto;
                        img {
                            max-width:120px;
                            max-height:120px;
                        }
                    }

                    > div {
                        flex-direction:column;
                        justify-content:flex-end;
                    }
                }
            }
        }
    }

    .results-footer {
        width:100%;
        height:108px;
        padding:24px;
        margin-bottom:0;
        margin-top:auto;
        align-self:flex-end;
        position:relative;
        display:none;
        @media (max-width:1368px) {
            height:96px;
        }
    }
}


//JP3 Specific Modifications/Overrides
/************* HEADER SEARCH ***************/
#header>.page-header{margin:0 auto;padding-top:24px;padding-bottom:10px;display:flex;width:1500px;max-width:92%;flex-flow:wrap;justify-content:space-between}
#search-bar{margin-left:30px;margin-right:30px;display:flex;flex:1;flex-direction:column;justify-content:center;z-index:5;min-height:40px}
#search-bar .ais-InstantSearch{transform:translateY(-7px)}
#jpp-search{height:40px;z-index:2;width:100%;background:#F3F3F3;padding:10px 12px;transition:0.44s ease;border-top-right-radius:0;border-bottom-right-radius:0;border:1px solid transparent;outline:none;color:#222;font-size:14px;font-weight:600;line-height:20px}
#jpp-search.active{border-color:#0088ce}
#search-bar .submit{margin-top:-7px;position:absolute;top:50%;transform:translateY(-50%);right:4px;z-index:3;transition:all .24s ease,height 0s;transition-delay:0s;transform-origin:top}
#search-bar .submit-button{width:30px;height:30px;background:transparent;border-radius:100%;color:transparent;border:0;transition:0.44s ease;cursor:pointer}
#search-bar .submit svg{position:absolute;top:50%;left:50%;transform:translateX(-50%) translateY(-50%) scale(0.8);pointer-events:none;z-index:1}
.search-area div.submit.filled:hover,.search-area div.submit.active{transform:scale(1.28) translateY(-50%)!important}
.search-area input#jpp-search.filled{box-shadow:inset 0 0 10px #e7e7e7;border-top-right-radius:100px;border-bottom-right-radius:100px;transition:1.4s ease}
.search-area div.submit.filled:hover svg path,.search-area div.submit.active svg path{stroke:#fff}
.search-area div.submit.filled:hover .submit-button,.search-area div.submit.active .submit-button{background:#0088ce!important}
.search-area .search-wrapper .background{position:fixed;content:'';z-index:-1;top:0;left:50%;transform:translateX(-50%);width:100vw;height:100vh;background:rgba(0,0,0,0.2);opacity:0;pointer-events:none;transition:all 0.24s ease,width 0s,height 0s}
.search-area .search-wrapper.active .background{opacity:1;pointer-events:auto;transition:all 0s,opacity 0.64s ease}
.search-results-panel{position:absolute;top:46px;left:-11px;transform:perspective(250px) translateZ(-5px);width:895px;pointer-events:none;background:#fff;opacity:0;transition:all 0.24s ease,height 0.6s ease,min-height 0.6s ease,width 0s;z-index:5000;display:flex;max-height:calc(100vh - 132px);overflow:hidden}
.search-results-panel.active{opacity:1;pointer-events:auto;transform:perspective(250px) translateZ(0);box-shadow:0 0 24px rgba(2,2,2,0.12)}
/**.search-results-panel.history .suggestions-column>div{height:100%}**/
.search-results-panel.history.queriesOnly{width:320px;transition:all 0.24s ease,height 0.6s ease,min-height 0.6s ease,width 0s}
.search-results-panel.history.queriesOnly .panel-product-area{display:none}
.search-results-panel.history.noHistory{display:none}
@media(max-width:1250px){
    .search-results-panel{left: -250px}
}
@media(min-width:769px){
    .search-results-panel.history{height:508px}
    .search-results-panel.results{height:508px}
}
.search-results-panel>div{position:absolute;top:0;left:0;right:0;opacity:0;pointer-events:none;z-index:1;display:flex;width:100%;height:100%}
.search-results-panel>div.active{opacity:1;pointer-events:auto;position:relative;z-index:2}
.search-results-panel .suggestions-column{width:235px;background:#FFF;display:flex;flex-direction:column;padding:13px 24px 24px 24px;position:relative}
@media(max-width:480px){
    .search-results-panel .suggestions-column{padding:16px}
}
@media(max-width:991px){
    .search-results-panel{max-width:100%;height:auto;left:0}
}
@media (max-width:768px) {
    .search-results-panel .suggestions-column{padding:10px 60px 16px 16px;width:100%;flex-direction:row;justify-content:space-between;}

}
.search-results-panel .suggestions-column>div{display:flex;flex-direction:column}
.search-results-panel .suggestions-column>div:nth-of-type(1) label{margin-bottom:8px}
.search-results-panel .suggestions-column>div:nth-of-type(3) .suggestion:nth-of-type(3){margin-bottom:0}
.search-results-panel .suggestions-column>div label{margin-top:12px;margin-bottom:8px}
.search-results-panel .suggestions-column>div .suggestions-wrapper{display:flex;flex-direction:column}
@media(max-width:768px){
    .search-results-panel .suggestions-column>div .suggestions-wrapper{display:flex;flex-direction:column;flex-wrap: wrap;max-height: 100px;overflow-x: hidden}
}
.search-results-panel .suggestions-column .query-suggestion{display:flex;color:#000;align-self:flex-start;cursor:pointer;line-height:1.2;margin-bottom:0}
.search-results-panel .suggestions-column .query-suggestion svg{transform:translateY(1px);margin-right:8px;display:none}
.search-results-panel .suggestions-column .query-suggestion svg path{transition:0.18s ease}
.search-results-panel .suggestions-column .query-suggestion span{font-size:15px;font-weight:500;margin-bottom:0;transition:0.18s ease}
.search-results-panel .suggestions-column .query-suggestion:hover span{color:#0088ce}
.search-results-panel .suggestions-column .query-suggestion:hover svg path{stroke:#0088ce}
.search-results-panel .suggestions-column .suggestion{border-radius:64px;color:#000;align-self:flex-start;margin-bottom:7px;cursor:pointer}
.search-results-panel .suggestions-column .suggestion span{line-height:1.2;user-select:none;display:block;text-align:left;font-size:15px;font-weight:500;transition:0.18s ease}
.search-results-panel .suggestions-column .suggestion:hover span{color:#0088ce}
.search-results-panel label{font-size:16px;font-weight:700}
.search-results-panel .panel-product-area{flex:1;position:relative;display:flex;flex-direction:column;background:#F7F7F7}
.search-results-panel .panel-product-area.hidden{display:none}
@media(max-width:768px){
    .search-results-panel .panel-product-area{order:1;min-height:253px;max-height:253px;margin-bottom:0;margin-top:auto;background:transparent}
    .search-results-panel .search-history{flex-direction:column}
    .search-results-panel .search-results{flex-direction:column}
}
.search-results-panel .search-results .submit-search{height:60px;background:#0088ce;border-radius:100px;width:588px;max-width:calc(100% - 32px);position:absolute;z-index:5;left:50%;transform:translateX(-50%);bottom:24px;display:flex;justify-content:center;transition:0.6s ease}
.search-results-panel .search-results .submit-search span{display:inline-block;color:#fff;text-align:center;pointer-events:none;line-height:60px;user-select:none;font-size:16px;font-weight:700}
.search-results-panel .search-results .submit-search svg{margin-left:12px;top:50%;transform:translateY(-50%) scale(1.1);position:relative}
@media(max-width:1368px){
    .search-results-panel .search-results .submit-search{height:48px;width:360px}
    .search-results-panel .search-results .submit-search span{line-height:48px;font-size:14px}
    .search-results-panel .search-results .submit-search svg{transform:translateY(-50%) scale(1)}
}
.search-results-panel .search-results .submit-search svg path{stroke:#fff}
.search-results-panel .search-results .submit-search:hover{filter:brightness(1.1);box-shadow:0 0 12px rgba(2,2,2,0.12)}
.search-results-panel .results-header{width:100%;padding:24px 24px 0;display:flex;position:relative;z-index:5}
.search-results-panel .trending-header{padding:0 24px;}
@media(max-width:480px){
    .search-results-panel .results-header{padding:16px 16px 0}
}
.search-results-panel .results-header .results-options{display:flex;transform:translateY(-6px);margin-left:24px;position:relative}
.search-results-panel .results-header .results-options .filter{position:relative;margin-right:12px}
.search-results-panel .results-header .results-options .filter:last-of-type{margin-right:0}
.search-results-panel .results-header .results-options .filter.active>a{background:#fff;border-color:#0088ce}
.search-results-panel .results-header .results-options .filter.active>a svg path{stroke:#0088ce}
.search-results-panel .results-header .results-options .filter a{height:36px;line-height:34px;user-select:none;padding:0 12px;background:#f5f5f5;border:1px solid #DCDCDC;border-radius:4px;position:relative;color:#000;font-weight:700;display:inline-block;min-width:124px;cursor:pointer}
.search-results-panel .results-header .results-options .filter a.filtering{background:#0088ce}
.search-results-panel .results-header .results-options .filter a.filtering span{color:#fff}
.search-results-panel .results-header .results-options .filter a.filtering span svg path{stroke:#fff}
.search-results-panel .results-header .results-options .filter a span{font-size:14px;pointer-events:none}
.search-results-panel .results-header .results-options .filter a svg{position:absolute;top:50%;transform:translateY(-50%);right:8px}
.search-results-panel .results-header .results-options .filter .dropdown{position:absolute;display:flex;flex-direction:column;opacity:0;background:#F5F5F5;border:1px solid #c3c3c3;padding:12px;border-radius:4px;transform:translateY(16px);pointer-events:none;transition:all .24s ease,height 0s;transition-delay:0s;box-shadow:0 10px 16px -2px rgba(2,2,2,0.36)}
.search-results-panel .results-header .results-options .filter .dropdown .option{display:flex;margin-bottom:8px;background:#fff;border-radius:4px;min-width:160px;transition:0.18s ease}
.search-results-panel .results-header .results-options .filter .dropdown .option:last-of-type{margin-bottom:0}
.search-results-panel .results-header .results-options .filter .dropdown .option span.option-title{font-size:14px;transition:0.18s ease}
.search-results-panel .results-header .results-options .filter .dropdown .option span.option-qty{align-self:flex-end;margin-right:0;margin-left:auto;font-size:11px;position:absolute;display:inline-block;background:#E6E6E6;color:#6A6A6A;height:16px;line-height:16px;top:50%;right:8px;transform:translateY(-50%);border-radius:4px;padding:1px 4px 0;transition:0.18s ease}
.search-results-panel .results-header .results-options .filter .dropdown .option:hover,.search-results-panel .results-header .results-options .filter .dropdown .option.active{border-color:#0088ce}
.search-results-panel .results-header .results-options .filter .dropdown .option:hover span.option-title,.search-results-panel .results-header .results-options .filter .dropdown .option.active span.option-title{color:#0088ce}
.search-results-panel .results-header .results-options .filter .dropdown .option:hover span.option-qty,.search-results-panel .results-header .results-options .filter .dropdown .option.active span.option-qty{color:#0088ce;background:rgba(0,136,206,0.15)}
.search-results-panel .results-header .results-options .filter.active>a{color:#0088ce;border-color:#0088ce;background:#fff}
.search-results-panel .results-header .results-options .filter.active .dropdown{pointer-events:auto;opacity:1;transform:translateY(8px)}
.search-results-panel .close,.search-results-panel .clear{position:absolute;right:24px;top:24px;color:#555;opacity:0.8;font-size:12px;user-select:none;z-index:20;cursor:pointer;text-decoration:none!important}
.search-results-panel .close:hover,.search-results-panel .clear:hover{opacity:1;color:#0088ce}
.search-results-panel .clear{color:#0088ce;right:4px;top:4px}
.search-results-panel .view-modes{position:absolute;bottom:24px;right:24px;z-index:10;display:none}
.search-results-panel .view-modes span{font-size:10px;color:#555;opacity:0.8;display:block;margin-right:10px;transform:translateY(9px);pointer-events:none;user-select:none}
.search-results-panel .view-modes .toggle{border:1px solid #dfdfdf;padding:4px;cursor:pointer;display:block;transition:0.18s ease;position:relative}
.search-results-panel .view-modes .toggle:not(.active){opacity:0.7}
.search-results-panel .view-modes .toggle:hover{opacity:1}
.search-results-panel .view-modes .toggle:hover:before{opacity:1;transform:translateX(-50%) translateY(0)}
.search-results-panel .view-modes .toggle:before{position:absolute;content:'';white-space:nowrap;padding:4px 8px;background:#0088ce;color:#fff;font-size:12px;text-align:center;left:50%;transform:translateX(-50%) translateY(6px);bottom:calc(100% + 8px);opacity:0;pointer-events:none;transition:0.18s ease;border-radius:4px;box-shadow:0 2px 8px rgba(2,2,2,0.32)}
.search-results-panel .view-modes .toggle.active{border-color:#0088ce;cursor:default}
.search-results-panel .view-modes .toggle svg{pointer-events:none}
.search-results-panel .view-modes .toggle.active svg g>g{stroke:#0088ce;fill:#0088ce}
.search-results-panel .view-modes .toggle svg g>g{stroke:#707070;fill:transparent;transition:0.18s ease}
.search-results-panel .view-modes .toggle.simple:before{content:'Simple'}
.search-results-panel .view-modes .toggle.enhanced:before{content:'Enhanced'}
.search-results-panel .product-results{display:flex;flex-wrap:wrap;align-content:flex-start;padding:20px 24px 24px 24px;transition:all .24s ease,height 0s;transition-delay:0s;position:relative;z-index:2;overflow-y:scroll}
@media(min-width:769px){
    .pre-search{
        overflow: hidden !important;
    }
}
@media(max-width:768px){
    .search-results-panel .results-header{padding:12px 24px 12px 24px}
    .search-results-panel .close{top:12px;right:16px}
    .search-results-panel .clear{right:auto;left:115px}
    .search-results-panel .product-results{width:100%;display:grid;grid-template-columns:repeat(2,1fr);grid-template-rows:auto}
}
@media(max-width:600px){
    .search-results-panel .product-results{padding:12px 16px 16px 16px;margin-bottom:12px;grid-template-columns:1fr}
}
@media(min-width:769px){
    .search-results-panel .product-results:not(.enhanced):not(.history){overflow:hidden}
}
.search-results-panel .product-results.changing{opacity:0;pointer-events:none}
.search-results-panel .product-results .product{position:relative;transition:all 0s,border 0.2s ease,box-shadow 0.2s ease,background 0.2s ease;background:#fff;align-self:flex-start;border:1px solid #E9E9E9;height:84px;width:calc(50% - 5px);margin-right:10px;margin-bottom:10px}
@media(max-width:768px){
    .search-results-panel .product-results .product{width:calc(100% - 20px);filter:brightness(0.98)}
}
.search-results-panel .product-results .product:nth-of-type(2n){margin-right:0}
.search-results-panel .product-results .product:hover{border-color:rgba(0,136,206,0.4);box-shadow:0 0 12px rgba(2,2,2,0.12)}
.search-results-panel .product-results .product *{transition:all 0.2s ease,height 0s,width 0s}
.search-results-panel .product-results .product.changing{transition:all 0s,opacity 0.2s!important}
.search-results-panel .product-results .product.changing *{transition:all 0s,opacity 0.2s!important}
.search-results-panel .product-results .product a{height:100%;width:100%;position:relative;display:block;color:#000}
.search-results-panel .product-results .product a>div{flex-direction:row;position:absolute;top:0;left:0;right:0;bottom:0;padding:16px;display:flex}
.search-results-panel .product-results .product a .image{position:relative;display:flex;justify-content:center;height:100%;aspect-ratio:1/1;margin-right:14px;transform:scale(1.05)}
.search-results-panel .product-results .product a .image img{width:100%;font-size:8px}
.search-results-panel .product-results .product a .info{display:flex;flex-direction:column}
.search-results-panel .product-results .product a .product-name{font-size:14px;font-weight:600;color:#000;margin-bottom:4px}
.search-results-panel .product-results .product a .sku{font-size:12px;color:#666;text-transform:uppercase;display:none}
.search-results-panel .product-results.enhanced{height:calc(100% - 60px);padding-top:12px}
.search-results-panel .product-results.enhanced .product{border:1px solid #e3e3e3;width:calc(20% - 13px)}
.search-results-panel .product-results.enhanced .product:nth-of-type(4n){margin-right:16px}
.search-results-panel .product-results.enhanced .product:nth-of-type(5n){margin-right:0}
.search-results-panel .results-footer{width:100%;height:108px;padding:24px;margin-bottom:0;margin-top:auto;align-self:flex-end;position:relative;display:none}
@media(max-width:1368px){
    .search-results-panel .product-results.enhanced .product{width:calc(25% - 9px);margin-right:12px;margin-bottom:12px}
    .search-results-panel .product-results.enhanced .product:nth-of-type(5n){margin-right:12px}
    .search-results-panel .product-results.enhanced .product:nth-of-type(4n){margin-right:0}
    .search-results-panel .product-results.enhanced .product:nth-of-type(3n){margin-right:12px}
    .search-results-panel .results-footer{height:96px}
}
.search-results-panel .product-results.enhanced .product a{padding-bottom:100%}
.search-results-panel .product-results.enhanced .product a .image{position:relative;display:flex;justify-content:center;height:152px;padding:0;margin-top:0;margin-bottom:auto}
.search-results-panel .product-results.enhanced .product a .image img{max-width:120px;max-height:120px}
.search-results-panel .product-results.enhanced .product a>div{flex-direction:column;justify-content:flex-end}
header.page-header .search-area{z-index:5}
header.page-header .search-area .search-wrapper .ais-InstantSearch{transform:translateY(-7px)}
header.page-header .search-area input#jpp-search::placeholder{color:#666!important}
header.page-header .search-area div.submit{margin-top:-7px}
header.page-header .header-links-wrapper,header.page-header .minicart-wrapper,header.page-header .logo-link{z-index:4}
header.page-header .search-results-panel .suggestions-column>div{position:relative}
header.page-header .search-results-panel .product-results .product a .product-name{font-size:14px;line-height:1.2}
header.page-header .search-results-panel .product-results .product a span.sku{display:none}
header.page-header .search-results-panel .clear{top:1px}
header.page-header .search-results-panel a{text-decoration:none!important}
